.dark {
    background-color: $primary;
    color: $secondary !important;

    a {
        color: $secondary;

        &:hover {
            color: $success;
        }
    }
}

.light {
    background-color: $secondary;
    color: $primary !important;
}

.project-social-media {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include media-breakpoint-down(lg) {
        padding-bottom: 0;
    }
}
/* this class is also used for theme.html */
.project-introduction {
    color: $primary;
    padding-top: 2rem;
    padding-bottom: 1rem;
    text-align: center;

    @include media-breakpoint-down(lg) {
        p {
            margin-bottom: 0;
        }
    }
}
.project-introduction-theme {
    color: $primary;
    @include indenting-top;
    // padding-top: 3rem;
    // padding-bottom: 3rem;
    @include indenting-bottom;
    text-align: center;

    .introduction-paragraph {
        p {
            margin-bottom: 0;
        }
    }
}

.project-credits {
    color: $primary;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .contect-credits {
        max-width: 200px;
        margin: 0;
        p,a {
            font-family: $font-title;
            text-align: center;
            margin-bottom: 0;
            font-size: 1.17rem;
            @include media-breakpoint-down(md) {
                font-size: 0.9rem;
            }
        }

        a {
            color: $warning;
            &:hover {
                color: $primary;
            }
        }
        @include media-breakpoint-down(md) {
            margin: 1rem 0;
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

/* this class is also used for theme.html */
.project-article {
    // padding: 0rem 0 3rem 0;
    padding: 0;
    // padding-bottom: 3rem;
    @include indenting-bottom();
    color: $primary;

    h5 {
        text-transform: uppercase;
        margin-bottom: 0;
    }
    p {
        margin-bottom: 0;
    }
    img {
        width: 100%;
        object-fit: cover;
    }
}

.project-image {
    padding: 3rem 0;
    img {
        width: 100%;
        object-fit: cover;
    }
    // TODO jaap checken of het werkt
    // @include media-breakpoint-down(sm) {
    //     padding: 0;
    //     padding-bottom: 2rem;
    //     padding-top: 2rem;
    // }
}

.project-visual-impression {
    background-color: $secondary;
    margin: 1rem 0;
    h2 {
        text-transform: uppercase;
        text-align: center;
        margin-top: 2rem;
    }
    .row {
        margin-bottom: 0.2rem;
    }
}

.project-image-article {
    color: $primary;
    padding: 2rem 0;
    p {
        // padding: 3rem 5rem;
        // margin-bottom: 0;
        // padding-top: 1rem;

        @include media-breakpoint-down(lg) {
            padding: 0 1rem;
        }
    }
    @include media-breakpoint-down(sm) {
        padding-top: 0;
        // padding-bottom: 1rem;
        p {
            padding: 0;
        }
    }
}

.carousel-title {
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    padding-top: 2rem;
    padding-bottom: 1.8rem;
}

.project-overview-title {
    text-transform: uppercase;
    text-align: center;
    color: $secondary;
    background-color: $success;
    margin-bottom: 0;
    padding-top: 4rem;
    // padding-bottom: 1.8rem;
}

@include media-breakpoint-down(lg) {
    .image-left {
        .container {
            margin-left: auto;
            padding-left: 1rem;
        }
    }
    .image-right {
        .container {
            margin-right: auto;
            padding-right: 1rem;
        }
    }

    .project-article {
        // padding: 2rem 0;
        h5 {
            margin-top: 3rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .project-article {
        h5 {
            margin-top: 2rem;
        }
    }
}
