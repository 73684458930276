.map-content {
    margin-top: 9.3rem;
    img {
        object-fit: cover;
    }

    .row {
        .col {
            // margin-top: 5rem;
            // display: none;
        }
    }
}
