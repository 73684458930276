/* navigation/menu list right-side of page */
.side-nave-list.text-black a {
    color: #5c5952;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 1000ms;
}

.side-nave-list {
    z-index: 10;
    position: fixed;
    bottom: 2rem;
    right: 0;
    display: inline-block;

    ul {
        padding-left: 0;
        padding-right: 2rem;
        list-style-type: none;
        text-align: right;
        li {
            float: right;
            display: inline;
            clear: both;
            text-transform: uppercase;
            a {
                color: $secondary;
                font-size: 1rem;
                letter-spacing: 0.1rem;
                text-decoration: none;
                padding: 0.5rem 0;
                padding-right: 0;
                font-weight: normal;
                font-size: 1rem;
                /* transition: font-size 0.2s ease-in-out; */
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 200ms;

                &:hover {
                    font-size: 1.1rem;
                    font-weight: bold;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 0.8rem;
                    &:hover {
                        font-size: 0.9rem;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        @media (max-height: 780px) {
            display: none;
        }
    }
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

a {
    text-decoration: none;
}

/* section with big image as background, class also used in project.html and theme.html */
.introduction-container {
    background-color: $primary;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-position: center;
    background-blend-mode: overlay;

    h1 {
        color: $secondary;
        text-transform: uppercase;
        // text-shadow: 1px 1px 15px rgba($color: #000000, $alpha: 0.3);
        padding-right: 18vw;
        line-height: 1em;
    }

    p {
        color: $secondary;
        // text-shadow: 1px 1px 15px rgba($color: #000000, $alpha: 0.8);
        margin-bottom: 3rem;
        padding-right: 18vw;
        // padding-left: 0.3rem;
        font-weight: 400;
        font-size: 1.5rem;
    }

    @include media-breakpoint-down(lg) {
        min-height: 70vh;
    }
    @include media-breakpoint-down(md) {
        h1 {
            padding-right: 30vw;
        }
        p {
            padding-right: 20vw;
            padding-left: 0.2rem;
            font-size: 1.2rem;
        }
        min-height: 60vh;
        @media (max-height: 730px) {
            h1 {
                padding-right: 0;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        h1 {
            // padding-right: 40vw;
            padding-right: 0;
            line-height: 1.2em;
        }
        p {
            // padding-right: 30vw;
            padding-right: 10vw;
            padding-left: 0.1rem;
            font-size: 1rem;
            margin-bottom: 2rem;
        }
        min-height: 60vh;
    }
    // @media (max-height: 700px) {
    //     h1 {
    //         font-size: 1.5rem;
    //         padding-right: 18vw;
    //         font-size: 2rem;
    //     }
    //     p {
    //         padding-right: 10vw;
    //     }
    // }
    @media (max-height: 500px) {
        h1 {
            font-size: 1.5rem;
            padding-right: 0;
        }
        p {
            padding-right: 10vw;
        }
    }
}

/* section title's in white backgrounds */
.title-container {
    padding: 5rem 0;

    a {
        text-decoration: none;

        h1 {
            color: $primary;
            font-family: $font-title;
            font-size: 3rem;
            text-transform: uppercase;
            text-align: center;
            margin: 0;
            padding: 0 8rem;

            img {
                padding-left: 1.5rem;
                height: 2.2rem;
                margin-bottom: 0.5rem;
            }

            @include media-breakpoint-up(lg) {
                p {
                    padding-left: 0.4rem;
                }
            }

            @include media-breakpoint-down(lg) {
                padding: 0 5rem;
            }

            @include media-breakpoint-down(md) {
                font-size: 2.5rem;
                padding: 0;

                img {
                    height: 1.8rem;
                }
            }
            @include media-breakpoint-down(sm) {
                font-size: 1.3rem;

                img {
                    height: 1.4rem;
                }
            }
            @media (max-height: 500px) {
                font-size: 1.3rem;
            }
        }
    }

    @include media-breakpoint-down(md) {
        padding: 3rem 0;
    }
}

.home-title {
    @media (max-height: 500px) {
        padding-top: 5rem;
    }
}

.home-carousel {
    z-index: 0;
    position: relative;

    .owl-nav.disabled {
        display: none;
    }
}
