.project-carousel {
    background-color: $success;
    height: 650px;
    @include media-breakpoint-up(xxl) {
        height: 750px;
        // height: calc(max-content + 100px);
    }
    @include media-breakpoint-down(lg) {
        height: 750px;
    }
    @include media-breakpoint-down(md) {
        height: 600px;
    }
    @include media-breakpoint-down(sm) {
        height: 500px;
    }

    .slide-container {
        color: $secondary;
        // padding-top: 2rem;

        .imagelink {
            position: relative;

            .next-arrow {
                position: absolute;
                margin-left: -100px;
                padding-top: 12vw;

                img {
                    width: 2rem;
                }
                @include media-breakpoint-down(lg) {
                    display: none;
                }
                @include media-breakpoint-up(xl) {
                    padding-top: 14vw;
                }
            }
        }

        .arrow-image {
            height: 3rem;
        }
        h2 {
            text-transform: uppercase;
            height: 7rem;
            // margin-top: 2rem;

            a {
                color: $secondary;
                font-size: 3rem;
            }
            @include media-breakpoint-down(xl) {
                // height: 10rem;

                a {
                    font-size: 2.8rem;
                }
            }

            @include media-breakpoint-down(lg) {
                height: 5rem;
                margin-top: 0;
                a {
                    font-size: 2rem;
                }
            }
            @include media-breakpoint-down(sm) {
                height: 7rem;
            }
        }

        h3 {
            margin-top: 1rem;
            margin-bottom: 2rem;
        }
    }

    .owl-prev {
        .owl-next {
            height: 1.5rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .owl-prev {
        display: none;
    }

    .owl-next {
        display: none;
        background-color: transparent;
        border: none;
        margin-top: -22%;
        margin-left: 74%;
        position: absolute;
        z-index: 3;
        img {
            height: 1.8rem;
        }
    }
}

//aangepast project-overzicht

.project-overview {
    padding-top: 1rem;
    padding-bottom: 5rem;
    background-color: $success;
    a {
        display: block;
        margin-bottom: 0.1rem;
        color: $secondary;

        overflow: hidden;
        div {
            background-size: cover;
            background-repeat: no-repeat;
            background-color: $primary;
            background-blend-mode: overlay;
            background-position: center;
            padding: 0.2em;

            .member-profile-item {
                background: none;
                padding-bottom: 1rem;
                p {
                    font-family: $font-title;
                    font-size: 1rem;
                    margin-bottom: 0;
                    text-align: center;
                    line-height: 1.3rem;
                }
                .designation {
                    font-weight: 200;
                }
            }
        }

        &:hover {
            div {
                background-blend-mode: screen;
            }
        }
    }
    h2 {
        text-align: center;
        text-transform: uppercase;
        color: $secondary;
        padding-bottom: 1rem;
        // padding-top: 1rem;
    }
}
