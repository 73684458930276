#menu-nav {
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 200;
    top: 0;
    left: 0;
    // background-color: rgb(0, 0, 0);
    // background-color: rgba(0, 0, 0, 0.9);
    background-color: $warning;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.5s;

    .overlay-control {
        z-index: 100;
        margin-top: 0.5rem;

        .switch-language {
            margin-right: 1rem;
        }

        p {
            color: $secondary;

            font-size: 1.5rem;
            font-family: $font-regular;
            cursor: pointer;

            a {
                display: block;
                font-size: 1.5rem;
                font-family: $font-regular;

                i {
                    font-size: 2rem;
                    display: block;
                    padding-bottom: 0.2rem;
                }
            }

            .close-icon {
                width: 1.15rem;
                height: 1.15rem;
                margin-left: 1rem;

                .close-x-icon {
                    stroke: $secondary;
                    transition: stroke 0.4s ease-in-out;
                }

                &:hover {
                    .close-x-icon {
                        stroke: $primary;
                    }
                }
            }

            .closebtn {
                top: 20px;
                right: 45px;
                font-size: 3.5rem;
                margin-bottom: 0.1rem;
            }
        }
    }

    .overlay-content {
        position: relative;
        width: 100%;
        text-align: center;
        // margin-top: 10%;
        height: calc(100vh - 8rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .menu-item-title {
            text-transform: uppercase;

            @include media-breakpoint-down(sm) {
                font-size: 2.2rem;
                font-weight: 200;
            }

            @media (max-height: 500px) {
                font-size: 2rem;
            }
        }

        @include media-breakpoint-down(sm) {
            height: 80%;
        }

        @media (max-height: 500px) {
            height: 80%;
        }

        .search-icon {
            height: 2rem;
            width: 2rem;

            .magnify-icon {
                stroke: $secondary;
                transition: stroke 0.4s ease-in-out;
            }

            &:hover {
                .magnify-icon {
                    stroke: $primary;
                }
            }
        }
    }

    .search-content {
        display: none;
        text-align: center;
        color: green;

        .searchbar-icon {
            height: 2rem;
            width: 2rem;

            @include media-breakpoint-down(sm) {
                width: 1rem;
                height: 1.5rem;
            }

            @media (max-height: 500px) {
                width: 1rem;
                height: 1.5rem;
            }

            .magnify-icon-bar {
                stroke: $primary;
                transition: stroke 0.4s ease-in-out;
            }

            &:hover {
                .magnify-icon-bar {
                    stroke: $warning;
                }
            }
        }
    }

    .overlay-content {
        a {
            text-transform: uppercase;
        }
    }

    a {
        cursor: pointer;
        // padding: 8px;
        text-decoration: none;
        font-family: $font-title;
        font-size: 3rem;
        color: $secondary;
        display: block;
        transition: 0.3s;
        margin-top: -0.2rem;
        margin-bottom: -0.2rem;
        font-weight: 400;
    }

    a:hover,
    a:focus {
        color: $primary;
    }

    &.search-menu {
        background-color: white;

        .overlay-content {
            display: none;
        }

        .search-content {
            display: flex;
            justify-content: center;
            margin-top: 5rem;

            form {
                position: relative;

                .easy-autocomplete {
                    width: auto !important;

                    // autoeasycomplete list
                    .eac-item {
                        a {
                            // background-color: red;
                            color: $primary;
                            font-size: 2rem;
                            line-height: 3rem;

                            &:hover {
                                color: $warning;
                            }

                            @include media-breakpoint-down(lg) {
                                font-size: 1.5rem;
                                line-height: 2.5rem;
                            }

                            @include media-breakpoint-down(sm) {
                                font-size: 0.9rem;
                                line-height: 2rem;
                            }

                            @media (max-height: 500px) {
                                font-size: 0.9rem;
                                line-height: 2rem;
                            }
                        }
                    }
                }

                input {
                    font-family: $font-title;
                    color: $primary;
                    font-size: 2.5rem;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    border-bottom: 1px solid $success;
                }

                button {
                    position: absolute;
                    top: 1rem;
                    right: 0;
                    color: $primary;
                    background-color: transparent;
                    border: none;
                    padding-left: 2rem;
                    -webkit-appearance: none;

                    &:hover {
                        color: $warning;
                    }
                }

                ul {
                    list-style-type: none;
                    color: $primary;
                    font-family: $font-regular;
                    font-size: 2rem;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    width: max-content;
                    padding-left: 0;

                    li {
                        text-align: start;

                        &:hover {
                            color: $warning;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    input {
                        min-width: 75vw;
                    }
                }

                @include media-breakpoint-down(lg) {
                    input {
                        width: 80vw;
                        font-size: 2rem;
                    }

                    button {
                        top: 0.5rem;
                    }
                }

                @include media-breakpoint-down(md) {
                    input {
                        width: 90vw;
                        font-size: 2rem;
                    }

                    button {
                        top: 0.5rem;
                    }
                }

                @include media-breakpoint-down(sm) {
                    input {
                        font-size: 1.2rem;
                    }

                    button {
                        display: inline-flex;
                        top: 0.2rem;
                        font-size: 2rem;
                    }

                    ul {
                        font-size: 1.2rem;
                        padding-left: 0;
                    }
                }

                @media (max-height: 500px) {
                    input {
                        // width: 18rem;
                        font-size: 1.2rem;
                    }

                    button {
                        display: inline-flex;
                        top: 0.2rem;
                        font-size: 2rem;
                    }

                    ul {
                        font-size: 1.2rem;
                        padding-left: 0;
                    }
                }
            }
        }

        .overlay-control {
            p {
                color: $primary;
            }

            a {
                color: $primary;
                transition: color 0.5s ease-in-out;

                &:hover {
                    color: $warning;
                }
            }

            .close-icon {
                .close-x-icon {
                    stroke: $primary;
                    transition: stroke 0.4s ease-in-out;
                }

                &:hover {
                    .close-x-icon {
                        stroke: $warning;
                    }
                }
            }
        }

        .closebtn {
            color: $primary;
        }
    }
}

.show-menu {
    #menu-nav {
        height: 100%;
    }

    overflow: hidden;
}

@include media-breakpoint-down(sm) {
    .eac-item {
        a {
            font-size: 1rem;
            padding-left: 0;
        }
    }
}

@media (max-height: 500px) {
    .eac-item {
        a {
            font-size: 1rem;
            padding-left: 0;
        }
    }
}