.member-head-image {
    margin-top: 7.5rem;
    text-align: center;

    img {
        text-align: center;
        max-width: 100%;
    }
}

.member-content-main {
    color: $primary;
    margin-bottom: 5.5rem;

    h2 {
        margin-bottom: 0;
        margin-left: 3rem;
        margin-right: 3rem;
    }

    a {
        text-decoration: none;
        color: $primary;
    }

    .member-name {
        margin-top: 2rem;
        margin-left: 0;
    }

    .member-contact {
        p {
            margin-bottom: 0;
            font-family: $font-title;
            a {
                font-weight: unset;
                transition: color 0.3s ease-in-out;

                &:hover {
                    color: $warning;
                }
            }
        }
    }

    .member-about {
        margin: 2rem 0;
    }

    .quote {
        text-transform: uppercase;
        text-align: center;
        font-size: 3rem;
        padding: 5.5rem 0;
    }
}

.member-projects-title {
    text-transform: uppercase;
    text-align: center;
    color: $secondary;
    padding-top: 4rem;
    background-color: $success;
    margin-bottom: 0;
}

@include media-breakpoint-down(md) {
    .member-content-main {
        h2 {
            font-size: 2rem;
            margin-left: 1rem;
            margin-right: 1rem;
        }
        .quote {
            font-size: 2rem;
            padding: 0;
        }
    }
}
@include media-breakpoint-down(sm) {
    .member-content-main {
        h2 {
            font-size: 1.5rem;
        }
        .quote {
            font-size: 1.5rem;
            padding: 0;
        }
    }
}
@media (max-height: 500px) {
    .member-content-main {
        h2 {
            font-size: 1.5rem;
        }
        .quote {
            font-size: 1.5rem;
            padding: 0;
        }
    }
}
