.jobs-article-container {
    margin-top: 2rem;
    margin-bottom: 7.5rem;
    @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
    }
    @media (max-height: 500px) {
        margin-bottom: 2rem;
    }

    .jobs-content {
        color: $primary;
        p:last-child {
            margin-bottom: 0;
        }

        h2 {
            text-transform: uppercase;
            padding-top: 3rem;
            padding-bottom: 1rem;
            font-size: 3rem;
        }
        h5 {
            text-transform: uppercase;
            font-size: 1rem;
        }
        a {
            text-decoration: none;
            color: $primary;
            font-weight: 400;

            transition: color 0.3s ease-in-out;
            h5 {
                img {
                    padding-left: 1rem;
                    height: 0.6rem;
                    margin-bottom: 0.2rem;
                }
            }
            p {
                display: inline-block;
                margin-bottom: -0.2rem;
            }

            &:hover {
                color: $warning;
            }
        }
        .link-to-application {
            text-transform: uppercase;
            // font-family: $font-title;
            .type-of-font {
                font-family: $font-title;
            }
            @include media-breakpoint-down(md) {
                margin-top: 0.8rem;
            }
        }
        img {
            height: 1rem;
            padding-left: 0.5rem;
        }

        @include media-breakpoint-down(lg) {
            // padding: 1.5rem 3rem;
        }
        @include media-breakpoint-down(md) {
            // padding: 1rem 1rem;
            h2 {
                // padding-bottom: 0.5rem;
                // font-size: 1.5rem;
            }
        }
        @include media-breakpoint-down(md) {
            h2 {
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 0;
                font-size: 1.5rem;
            }
            p {
                margin-bottom: 0;
            }
            a {
                margin-bottom: 2rem;
            }
        }
        @media (max-height: 500px) {
            h2 {
                padding-top: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            p {
                margin-bottom: 0;
            }
            a {
                margin-bottom: 2rem;
            }
        }

        @include media-breakpoint-up(xl) {
            // padding: 1.5rem 10rem;
        }
    }

    .job-description {
        a {
            text-decoration: underline;
        }
    }
}
