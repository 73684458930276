.team-members-overview {
    margin-bottom: 0.3rem;
    a {
        display: block;
        margin-bottom: 0.1rem;
        color: $secondary;

        overflow: hidden;
        div {
            background-size: cover;
            background-repeat: no-repeat;
            background-color: $primary;
            background-blend-mode: overlay;
            background-position: center;
            padding: 0.2em;

            .member-profile-item {
                background: none;
                padding-bottom: 1rem;
                p {
                    font-family: $font-title;
                    font-size: 1rem;
                    margin-bottom: 0;
                    text-align: center;
                    line-height: 1.3rem;
                }
                .designation {
                    font-weight: 200;
                }
            }
        }

        &:hover {
            div {
                background-blend-mode: screen;
            }
        }
    }
    h2 {
        text-align: center;
        text-transform: uppercase;
        color: $primary;
        padding-bottom: 1rem;
    }
}

.theme-introduction {
    h1 {
        font-size: 1.5rem;
    }
}
.team-container {
    margin-top: 11rem;
    @include media-breakpoint-down(lg) {
        margin-top: 10rem;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 8rem;
    }
}
