/* this class is used in the pages: about, jobs and team */
.header-image-container {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-color: $primary;
    h1 {
        color: $secondary;
        text-transform: uppercase;
        max-width: 70%;
        text-align: center;
        text-shadow: 1px 1px 15px rgba($color: #000000, $alpha: 0.5);
        padding-top: 5.2rem;
    }

    @include media-breakpoint-down(lg) {
        min-height: 70vh;
    }

    @include media-breakpoint-down(md) {
        min-height: 60vh;
    }
    @media (max-height: 500px) {
        min-height: 70vh;
        h1 {
            padding-top: 6.2rem;
        }
    }
}

/* this class is used in the pages: about, jobs and team */
.indent-title {
    padding-left: 11rem;
    padding-right: 11rem;

    @include media-breakpoint-down(lg) {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    @include media-breakpoint-down(md) {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    @include media-breakpoint-down(sm) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

/* class used in about.html, team.html and text.html */
.article-one {
    padding-top: 3rem;
    padding-bottom: 6rem;
    color: $primary;
    h3 {
        text-transform: uppercase;
        text-align: center;
        padding: 3rem 2rem 2.5rem 2rem;
        font-size: 3rem;
        @include media-breakpoint-down(md) {
            padding-top: 4rem;
            padding-bottom: 3.5rem;
            font-size: 2rem;
        }
    }
    p {
        padding-top: 1.5rem;
        padding-bottom: 0.5rem;
    }
    @include media-breakpoint-down(md) {
        padding-top: 2rem;
        padding-bottom: 2rem;
        p {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 2rem;
        }

        h3 {
            padding: 0;
            margin-bottom: 2rem;
            // font-size: 1.5rem;
        }
    }
    @include media-breakpoint-down(sm) {
        h3 {
            font-size: 1.5rem;
        }
    }
    @media (max-height: 500px) {
        padding-top: 2rem;
        padding-bottom: 2rem;
        p {
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 2rem;
        }

        h3 {
            padding: 0;
            margin-bottom: 2rem;
            font-size: 1.5rem;
        }
    }
}

.introduction-sub {
    h6 {
        font-family: $font-regular;
        line-height: 1.5;
    }
    font-weight: 400;
}
