.theme-version {
    padding-top: 1rem;
    h2 {
        color: $primary;
        text-align: center;
        text-transform: uppercase;
        font-size: 3rem;
        @include media-breakpoint-down(md) {
            font-size: 2rem;
        }
    }
    .project-item-container {
        .position-absolute {
            width: 100%;

            h6 {
                color: $secondary;
                font-family: $font-title;
                text-align: center;
                padding-bottom: 1rem;
                letter-spacing: 0.1rem;
            }
        }
        img {
            object-fit: cover;
        }
    }
}
.project-article-wrapper {
    padding-bottom: 3rem;
}
