.gallery-carousel {
    background-color: $secondary;
    padding-bottom: 3rem;
    position: relative;

    .slide-container {
        color: $secondary;

        .arrow-image {
            height: 3rem;
        }
        h2 {
            text-transform: uppercase;
            height: 7rem;
            margin-top: 3rem;

            a {
                color: $secondary;
            }
        }
        .gallery-image {
            background-size: cover;
            background-position: center;
        }
    }

    .owl-nav {
        width: 100%;
        top: 35%;
        position: absolute;
        @include media-breakpoint-down(xxl) {
            top: 30%;
        }
        @media (max-width: 1300px) {
            top: 28%;
        }
        @include media-breakpoint-down(lg) {
            top: 28%;
        }
        @media (max-width: 950px) {
            top: 27%;
        }
        @media (max-width: 820px) {
            top: 25%;
        }
    }

    .owl-prev {
        .owl-next {
            height: 1.5rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .owl-prev {
        background-color: transparent;
        border: none;
        font-size: 4rem;
        color: $secondary;
        position: absolute;
        z-index: 3;
        left: 6.7rem;

        font-weight: 100;
        img {
            width: 2.5rem;
        }
    }

    .owl-next {
        background-color: transparent;
        border: none;
        font-size: 4rem;
        color: $secondary;
        position: absolute;
        z-index: 3;
        right: 6.7rem;

        font-weight: 100;
        img {
            width: 2.5rem;
        }
    }

    @include media-breakpoint-up(xl) {
        .owl-prev,
        .owl-next {
            img {
                width: 3rem;
            }
        }
        // .owl-prev {
        //     img {
        //         width: 3rem;
        //     }
        // }

        // .owl-next {
        //     img {
        //         width: 3rem;
        //     }
        // }
    }
    @include media-breakpoint-down(xl) {
        .owl-prev {
            left: 3.7rem;
        }
        .owl-next {
            right: 3.7rem;
        }
    }
    @include media-breakpoint-down(lg) {
        .owl-prev {
            left: 2rem;
            img {
                width: 2.5rem;
            }
        }
        .owl-next {
            right: 2rem;
            img {
                width: 2.5rem;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .owl-prev,
        .owl-next {
            display: none;
        }
        // .owl-prev {
        //     display: none;
        // }

        // .owl-next {
        //     display: none;
        // }
    }
    .owl-nav {
        button {
            outline: none;
        }
    }
}
