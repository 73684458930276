.navigation-submenu {
    position: absolute;
    z-index: 90;
    top: 4.0rem;
    right: 0;
    left: 0;
    background-color: $secondary;
    padding-top: 2.2rem;

    @include media-breakpoint-down(sm) {
        top: 2.2rem;
    }
    @media (max-height: 500px) {
        top: 2.2rem;
    }

    ul {
        display: flex;
        text-align: center;
        justify-content: center;
        padding-left: 0rem;
        li {
            padding: 0 2rem;
            list-style-type: none;
            a {
                text-decoration: none;
                color: $primary;
                font-family: $font-title;

                &:hover {
                    // text-decoration: underline;
                    border-bottom: 1px solid $primary;
                }
                &.active {
                    // text-decoration: underline;
                    border-bottom: 1px solid $primary;
                }
            }
            @include media-breakpoint-down(md) {
                padding: 0 1.3rem;
            }

            @include media-breakpoint-down(sm) {
                padding: 0 0.6rem;
            }
        }
    }
    .active {
        border-bottom: 1px solid $primary;
    }
}
