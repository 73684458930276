.project-overview-container {
    margin-top: 2rem;

    .grid-layout {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        grid-column-gap: 4rem;
        grid-row-gap: 1rem;
        grid-auto-rows: minmax(15rem, auto);
        grid-auto-flow: dense;
        margin-bottom: 5px;

        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
            grid-auto-rows: minmax(15rem, auto);
        }
        @include media-breakpoint-down(xxl) {
            grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
            grid-auto-rows: minmax(13rem, auto);
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
            grid-auto-rows: minmax(18rem, auto);
        }
    }

    .grid-item {
        font-size: 14px;
        font-weight: bold;
        color: $secondary;
        background-size: cover;
        background-position: center;
        background-blend-mode: overlay;
        transition: background-color 0.2s ease-in-out;
        grid-row-end: span 1;
        grid-column-end: span 1;

        p {
            font-family: $font-title;
            font-weight: 200;
            text-align: start;
            color: $primary;
            padding-top: 0.5rem;
            font-size: 1.1rem;
            font-weight: 400;
        }
    }
}
