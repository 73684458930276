// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// custom bootsrap  gutters
$gutters: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 4.75,
);

// custom ratio bootstrap values
$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
    "2x1": calc(1 / 2 * 100%),
    "1x2": calc(2 / 1 * 100%),
    "32x30": calc(30 / 32 * 100%),
    // custom ratio for project items in proects.html
    "391x316": calc(316 / 391 * 100%),
    // custom ratio for member profile image in team.html in .team-members-overview
    "65x37": calc(37 / 65 * 100%),
    // custom ratio for header-image in newsitem
    "21x10": calc(10 / 21 * 100%),
);

$primary: #5c5952;
/* grey */
$secondary: #ffffff;
/* white */
$success: #bebcba;
/* light grey */
$info: #e6e6e6;
/* broken white */
$warning: #9d9b97;
/* brow grey */
$danger: #e3a51a;
/* oker geel */
$light: #e2eef0;
/* baby-blauw */
$dark: #00929f;
/* vel blauw */

$font-primary: "main-font";
$font-title: "tf";
$font-regular: "regularf";
$font-medium: "mediumf";
$font-semibold: "semiboldf";

@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";

// // Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// // Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";

// @import "./animate.min.scss";
// @import "./mixins.scss";
// @import "./custom.scss";
// @import "./responsive.scss";

@import "./home.scss";
@import "./base.scss";
@import "./projects.scss";
@import "./about.scss";
@import "./team.scss";
@import "./jobs.scss";
@import "./news.scss";
@import "./member.scss";
@import "./menu.scss";
@import "./submenu.scss";
@import "./contact.scss";
@import "./project.scss";
@import "./gallerycarousel.scss";
@import "./projectcarousel.scss";
@import "./theme.scss";
@import "./text.scss";
@import "./project_bundle.scss";
@import "./project_overview.scss";

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=9a17e018-1f28-461b-acb5-8ac82f1ea158");

@font-face {
    font-family: $font-title;
    src: url("/static/fonts/d999c54c-bf00-4fcf-8ff9-28338985b6b2.woff2")
            format("woff2"),
        url("/static/fonts/d7d64e4b-003a-439e-91cb-20747878a066.woff")
            format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Info Corr Offc W02 Regular";
    src: url("/static/fonts/b57602f9-d918-493b-9a23-00433185c540.woff2")
            format("woff2"),
        url("/static/fonts/1c6d6921-899e-4b93-9df6-1cc9d639e6fa.woff")
            format("woff");
    font-display: swap;
}

@font-face {
    font-family: $font-regular;
    src: url("BarlowSemiCondensed-Regular-webfont.eot");
    src: url("BarlowSemiCondensed-Regular-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("/static/fonts/BarlowSemiCondensed-Regular-webfont.woff")
            format("woff"),
        url("BarlowSemiCondensed-Regular-webfont.ttf") format("truetype"),
        url("BarlowSemiCondensed-Regular-webfont.svg#barlow_semi_condensedregular")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-medium;
    src: url("BarlowSemiCondensed-Medium-webfont.eot");
    src: url("BarlowSemiCondensed-Medium-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("BarlowSemiCondensed-Medium-webfont.woff2") format("woff2"),
        url("/static/fonts/BarlowSemiCondensed-Medium-webfont.woff")
            format("woff"),
        url("BarlowSemiCondensed-Medium-webfont.ttf") format("truetype"),
        url("BarlowSemiCondensed-Medium-webfont.svg#barlow_semi_condensedmedium")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: $font-semibold;
    src: url("BarlowSemiCondensed-SemiBold-webfont.eot");
    src: url("BarlowSemiCondensed-SemiBold-webfont.eot?#iefix")
            format("embedded-opentype"),
        url("/static/fonts/BarlowSemiCondensed-SemiBold-webfont.woff")
            format("woff"),
        url("BarlowSemiCondensed-SemiBold-webfont.ttf") format("truetype"),
        url("BarlowSemiCondensed-SemiBold-webfont.svg#barlow_semi_condensedsemibold")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
