.drop-down-container {
    margin-top: 4.2rem;
    padding-top: 2rem;
    color: $primary;
    // height: 5.5rem;
    height: 3rem;
    font-family: $font-title;
    font-weight: 600;
    .scrollable {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .full-height {
        height: calc(100vh - 6.2rem);
    }
    .drop-down-start {
        display: block;
    }

    .drop-down-open {
        display: none;
    }

    .drop-down-selected {
        display: none;
    }
    .drop-down-list {
        transition: max-height 0.3s;
        overflow: hidden;
        position: relative;
        z-index: 2;
        background-color: rgba($color: $secondary, $alpha: 0.85);
        width: 100vw;
        margin-left: -1rem;
        //        height: 100%;
        max-height: 0;
        @media (max-height: 500px) {
            height: unset;
        }
        ul {
            list-style-type: none;
            li {
                line-height: 1.7rem;
                font-family: $font-title;
                a {
                    color: $primary;
                    line-height: 2rem;
                    transition: color 0.15s ease-in;

                    &:hover {
                        color: $warning;
                    }
                }
            }
            .active {
                border-bottom: 1px solid $primary;
                display: inline-block;
                height: 2.3rem;
            }

            .explain-list {
                text-transform: uppercase;
                font-weight: 100;
                color: $warning;
                font-family: $font-title;
                font-size: 0.8rem;
                margin-bottom: 0.2rem;
            }
        }
    }

    a {
        text-decoration: none;
        color: $primary;
    }
    .arrow {
        transition: transform 0.3s ease-in-out;
    }
    &.filter-menu-open {
        .drop-down-list {
            min-height: calc(100vh - 8.15rem);
            max-height: 100vh;
            @media (max-height: 500px) {
                max-height: max-content;
            }
        }
        .arrow {
            transform: rotate(180deg);
        }

        .drop-down-list {
            display: block;
        }
    }

    &.filter-menu-selected {
        .arrow {
            transform: rotate(270deg);
        }
    }
    @include media-breakpoint-down(md) {
        a {
            font-size: 1.3rem;
        }
    }
}

.drop-down-list-selected {
    .drop-down-selected {
        display: block;
    }
    .drop-down-start {
        display: none;
    }
    .drop-down-open {
        display: none;
    }
    .drop-down-list {
        display: none;
    }
}

.project-title {
    text-align: center;
    color: $primary;
    text-transform: uppercase;
    padding-top: 1.5rem;
    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
    }
    @include media-breakpoint-down(md) {
        h2 {
            font-size: 1.5rem;
        }
    }
}

/* this class is also used for theme.html and news.html*/
.projects-overview-container {
    background-color: $secondary;
    margin: 2rem 0;

    .project-item-container {
        margin-bottom: 2.6rem;

        .image-ratio-styling {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        h6 {
            color: $primary;
            padding-top: 0.5rem;
            margin-bottom: 0;
            font-size: 1.1rem;
            font-weight: 400;
            display: block;
            height: 4rem;
        }

        p {
            font-size: 1.1rem;
            font-family: $font-title;
            font-weight: 400;
            margin-bottom: 0.1rem;
        }

        a {
            display: block;
            text-decoration: none;
            color: $primary;
            font-size: 1.1rem;
            font-family: $font-title;
            font-weight: 400;
        }

        /* this class only effects on news.html */
        .project-item-content {
            height: 7rem;
            margin-top: 0.5rem;
            p {
                margin: auto;
            }
        }
    }
}
.cat-4,
.cat-1 {
    background-color: $secondary;
}

.noscroll {
    overflow: hidden;
}
