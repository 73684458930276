.project-bundle-container {
    margin-top: 2rem;
    h2 {
        color: $primary;
        text-transform: uppercase;
        text-align: center;
        font-size: 3rem;
        @include media-breakpoint-down(md) {
            font-size: 2rem;
        }
        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
        @media (max-height: 500px) {
            font-size: 1.5rem;
        }
    }

    .grid-layout {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        grid-gap: 5px;
        grid-auto-rows: minmax(15rem, auto);
        grid-auto-flow: dense;
        margin-bottom: 5px;

        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
            grid-auto-rows: minmax(22rem, auto);
        }
        @include media-breakpoint-down(xxl) {
            grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
            grid-auto-rows: minmax(18rem, auto);
        }
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
            grid-auto-rows: minmax(13rem, auto);
        }
        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
            grid-auto-rows: calc(100vw - 8.5rem);
        }
    }

    .grid-item {
        padding: 1rem;
        font-size: 14px;
        font-weight: bold;
        color: $secondary;
        background-color: $primary;
        background-size: cover;
        background-position: center;
        background-blend-mode: overlay;
        transition: background-color 0.2s ease-in-out;
        grid-row-end: span 1;
        grid-column-end: span 1;

        p {
            margin-bottom: 0;
            font-family: $font-title;
            font-weight: 200;
            text-align: center;
        }

        &:nth-child(odd) {
            background-color: #424242;
        }
        &:hover {
            background-color: transparent;
        }
    }

    .grid2items {
        @include media-breakpoint-up(md) {
            .grid-item:nth-child(2) {
                grid-column-end: span 2;
            }
        }
    }
    .grid3items {
        @include media-breakpoint-between(sm, md) {
            .grid-item:nth-child(3) {
                grid-column-end: span 2;
            }
        }
    }

    .grid4items {
        @include media-breakpoint-between(sm, md) {
            .grid-item:nth-child(3) {
                grid-column-end: span 2;
            }
            .grid-item:nth-child(4) {
                grid-column-end: span 2;
            }
        }
        @include media-breakpoint-up(md) {
            .grid-item:nth-child(3) {
                grid-row-end: span 2;
            }
            .grid-item:nth-child(4) {
                grid-column-end: span 2;
            }
        }
    }

    .grid5items {
        .grid-item:nth-child(3) {
            grid-row-end: span 2;
        }
    }
    .grid6items {
        @include media-breakpoint-between(sm, md) {
            .grid-item:nth-child(4),
            .grid-item:nth-child(5) {
                grid-row-end: span 2;
            }
            .grid-item:nth-child(3),
            .grid-item:nth-child(6) {
                grid-column-end: span 2;
            }
        }
        @include media-breakpoint-up(md) {
            .grid-item:nth-child(4) {
                grid-row-end: span 2;
            }
            .grid-item:nth-child(5),
            .grid-item:nth-child(6) {
                grid-column-end: span 2;
            }
        }
    }
    .grid7items {
        @include media-breakpoint-between(sm, md) {
            .grid-item:nth-child(7) {
                grid-column-end: span 2;
            }
        }
        @include media-breakpoint-up(md) {
            .grid-item:nth-child(4) {
                grid-row-end: span 2;
            }
            .grid-item:nth-child(5) {
                grid-column-end: span 2;
            }
        }
    }
    .grid8items {
        @include media-breakpoint-up(sm) {
            .grid-item:nth-child(2),
            .grid-item:nth-child(4) {
                grid-column-end: span 2;
            }
            .grid-item:nth-child(5),
            .grid-item:nth-child(6) {
                grid-row-end: span 2;
            }
        }
    }
    .grid9items {
        @include media-breakpoint-up(sm) {
            .grid-item:nth-child(5),
            .grid-item:nth-child(7) {
                grid-column-end: span 2;
            }
            .grid-item:nth-child(6) {
                grid-row-end: span 2;
            }
        }
    }
    .grid10items {
        @include media-breakpoint-up(sm) {
            .grid-item:nth-child(8) {
                grid-column-end: span 2;
            }

            .grid-item:nth-child(7) {
                grid-row-end: span 2;
            }
        }
    }
}

// .span-2 {
//     // grid-column-end: span 2;
//     grid-row-end: span 2;
// }

// .span-3 {
//     // grid-column-end: span 3;
//     grid-column-end: span 2;
//     // grid-row-end: span 4;
// }
