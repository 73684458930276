/* top header: logo and menu-icon */
h1 {
    font-family: $font-title;
    font-size: 5rem;
}

/* title in content */
h2,
h3 {
    font-family: $font-title;
    font-size: 2.5rem;
}

h5,
h4,
h6 {
    font-family: $font-title;
    font-size: 1.3rem;
}

p {
    font-family: $font-regular;
    font-size: 1.3rem;
}

li {
    font-family: $font-regular;
    font-size: 1.3rem;
}

a {
    font-size: 1.3rem;
    color: $primary;
    font-weight: 600;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: $warning;
    }
}

@include media-breakpoint-down(xl) {
    h1 {
        font-size: 4rem;
    }
}

@include media-breakpoint-down(lg) {
    h1 {
        font-size: 3rem;
    }
    h2,
    h3 {
        font-size: 1.5rem;
    }
}

@include media-breakpoint-down(md) {
    h1 {
        font-size: 2.5rem;
    }
    p,
    a,
    li {
        font-size: 1rem;
    }
    h2,
    h3 {
        font-size: 1.5rem;
    }
}

@include media-breakpoint-down(sm) {
    h1 {
        font-size: 1.5rem;
    }
}
@media (max-height: 500px) {
    h1,
    h2,
    h3 {
        font-size: 1.5rem;
    }
}

@mixin indenting-bottom {
    padding-bottom: 3rem;
    @include media-breakpoint-down(lg) {
        padding-bottom: 2rem;
    }
}
@mixin indenting-top {
    padding-top: 3rem;
    @include media-breakpoint-down(lg) {
        padding-top: 2rem;
    }
}

/* this class is used for project.html, theme.html and member.html */
.indent-content-introduction {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }
    @media (max-height: 500px) {
        max-width: 100%;
    }
}
.indent-content-introduction-project {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

/* this class is used in job_detail.html, member.html and newsitem.html */
.indenting-header-image {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }
    @media (max-height: 500px) {
        max-width: 100%;
    }
}

.horizontal-container {
    .horizontal-line {
        background-color: $success;
        height: 2px;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;

        @include media-breakpoint-down(sm) {
            max-width: 90%;
        }
    }
}

.horizontal-container-member {
    margin: 0 -1rem;
}

/* menu icon button right-upper corner page */
.top-nav-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: transparent;
    padding-bottom: 1rem;
    transition: background-color 0.2s ease-in-out;
    &.white-background {
        /* veranderen class name */
        .color-logo {
            transition: fill 0.5s ease-in-out;
            fill: $primary;
        }
        .align-icon-container .menu-icon .line {
            background-color: $primary;
        }
    }

    .top-nav-content {
        margin-top: 1rem;

        a img {
            height: 35px;
            @include media-breakpoint-down(sm) {
                height: 24px;
            }
        }
        svg {
            height: 35px;
            @include media-breakpoint-down(sm) {
                width: 14rem;
            }
        }
    }

    .align-icon-container {
        display: flex;
        justify-content: flex-end;

        .menu-icon {
            display: flex;
            width: 30px;
            height: 35px;
            position: relative;
            cursor: pointer;
            margin-top: 1rem;

            .line {
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                background: white;
                border-radius: 8px;
                opacity: 1;
                left: 0;
            }
        }
    }
}

.white-background {
    background-color: $secondary;
}

.top-nav-home {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    .top-nav-content {
        margin-top: 1rem;

        a img {
            height: 35px;
        }
    }

    .align-icon-container {
        display: flex;
        justify-content: flex-end;

        .menu-icon {
            display: flex;
            width: 30px;
            height: 35px;
            position: relative;
            cursor: pointer;
            margin-top: 1rem;

            .line {
                display: block;
                position: absolute;
                height: 2px;
                width: 140%;
                background: $secondary;
                border-radius: 8px;
                opacity: 1;
                left: 0;
            }
        }
    }
}

.colorbackground-image {
    background-color: $primary;
}

/* class used in theme.html and project.html */
.project-theme-specefic {
    padding-top: 3.5rem;
    justify-content: unset;

    p {
        line-height: 1.8rem;
        a {
            display: block;
            color: $secondary;
            font-family: $font-title;
            font-weight: 200;
            transition: color 0.2s ease-in-out;

            &:hover {
                color: $success;
            }
        }
    }
    p:nth-child(1) {
    }
    .custom-container {
        min-height: calc(100vh - 5rem);

        @include media-breakpoint-down(lg) {
            min-height: calc(70vh - 5rem);
        }
        @include media-breakpoint-down(md) {
            min-height: calc(60vh - 5rem);
        }
        @include media-breakpoint-down(sm) {
            min-height: calc(60vh - 5rem);
        }
    }
}

.custom-height {
    @include media-breakpoint-up(lg) {
        min-height: 82vh;
    }
}
.custom-theme-intro {
    @include media-breakpoint-up(lg) {
        min-height: calc(82vh - 5rem) !important;
    }
}

.title-under-logo {
    display: block;
    font-family: $font-title;
    margin-top: 0.8rem;
    color: $secondary !important;
    @include media-breakpoint-down(sm) {
        margin-top: 0;
    }
}

/* footer section */
.footer-container {
    background-color: $success;
    color: $primary;
    padding: 3rem 0;
    text-align: center;
    position: relative;
    z-index: 50;

    h2 {
        text-transform: uppercase;
        font-size: 3rem;
        @include media-breakpoint-down(md) {
            font-size: 2rem;
        }
        @include media-breakpoint-down(sm) {
            font-size: 1.5rem;
        }
        @media (max-height: 500px) {
            font-size: 1.5rem;
        }
    }

    .footer-content {
        p {
            font-family: $font-title;
            margin-bottom: 0;
        }

        a {
            font-family: $font-title;
            text-decoration: none;
            color: $primary;
            font-weight: unset;
            &:hover {
                color: $warning;
            }
        }

        input {
            margin-top: 0.5rem;
        }

        input[type="text"] {
            background-color: $info;
            border: none;
            color: $primary;
            outline: none;
        }
        input[type="submit"] {
            background-color: transparent;
            color: $primary;
            font-family: $font-title;
            font-size: 1.3rem;
            border: none;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: $warning;
            }
            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }
        .form-is-sent {
            color: #fff;
        }
    }
}

/* used in base.html and in project.html */
.social-media-icons {
    img {
        height: 1.5rem;
        margin: 0 0.5rem;
    }
    a {
        padding: 0.2rem;
        .social-icon {
            height: 30px;
            width: 30px;

            .footer-icon {
                fill: $primary;
                height: 20px;
                transition: fill 0.2s ease-in-out;
            }
            &:hover,
            :focus {
                .footer-icon {
                    fill: $warning;
                }
            }
        }
    }
}

.footer-white {
    background-color: white;
    .form-is-sent {
        color: $primary !important;
    }
}

.padding-bottom-image {
    @include indenting-bottom();
    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }
}

.padding-top-image {
    @include media-breakpoint-down(lg) {
        padding-top: 2rem !important;
    }
}
.padding-bottom-container {
    @include media-breakpoint-down(lg) {
        padding-bottom: 0 !important;
    }
}
.padding-top-container {
    @include media-breakpoint-up(lg) {
        padding-top: 3rem !important;
    }
}

.margin-bottom-content {
    p {
        margin-bottom: 0;
    }
}
.padding-top-bottom-responsive {
    @include media-breakpoint-down(lg) {
        padding-top: 0 !important;
        padding-bottom: 2rem !important;
    }
}
.padding-top-text {
    @include media-breakpoint-down(lg) {
        padding-top: 2rem !important;
    }
}
