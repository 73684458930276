/* class used in news.html and text.html */
.news-title {
    text-align: center;
    color: $primary;
    text-transform: uppercase;
    @include media-breakpoint-down(md) {
        h2 {
            font-size: 1.5rem;
        }
    }
}

.news-item {
    margin-top: 9.3rem;
    color: $primary;
    h2 {
        text-transform: uppercase;
    }

    .news-header {
        text-align: center;
        p {
            font-family: $font-title;
        }
        h2 {
            font-size: 3rem;
        }
    }

    .news-image {
        text-align: center;

        img {
            object-fit: cover;
            max-width: 100%;
        }
    }

    .social-media-icons {
        text-align: start;
        margin: 1rem 0;
        img {
            height: 1.5rem;
            margin: 0 0.5rem;
        }
    }

    .news-content {
        // margin-top: 2rem;
        margin-bottom: 6rem;

        p {
            padding: 0.5rem 0;
            @include media-breakpoint-down(md) {
                padding: 0;
                margin-bottom: 2rem;
            }
        }
        h2,
        h5 {
            text-align: center;
            font-size: 3rem;
            padding: 6rem 0;
        }
        span {
            font-family: $font-title;
            font-size: 1rem;
            a {
                color: $primary;
            }
        }

        @include media-breakpoint-down(md) {
            h2,
            h5 {
                font-size: 2rem;
                padding: 0;
                margin-bottom: 2rem;
            }
        }
        @include media-breakpoint-down(sm) {
            h2,
            h5 {
                padding: 0;
                margin-bottom: 2rem;
                font-size: 1.5rem;
            }
        }
        @media (max-height: 500px) {
            h2,
            h5 {
                padding: 0;
                margin-bottom: 2rem;
                font-size: 1.5rem;
            }
        }
    }

    @include media-breakpoint-down(md) {
        h2 {
            font-size: 2rem;
        }
        .news-header {
            h2 {
                font-size: 2rem;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        .news-header {
            h2 {
                font-size: 1.5rem;
            }
        }
    }
    @media (max-height: 500px) {
        .news-header {
            h2 {
                font-size: 1.5rem;
            }
        }
    }
}

/* this class is a addition to scss class ".projects-overview-container" in "projects.scss" */
.news-version {
    margin-top: 5.2rem;
    p {
        margin-top: 0.5rem;
    }
}
